import { Routes } from "@angular/router";
import { IntegrityCheckComponent } from "./integrity-check/integrity-check.component";
import { IntegrityCheckResultComponent } from "./integrity-check/pages/integrity-check-result/integrity-check-result.component";
import { IntegritySearchHistoryComponent } from "./integrity-check/pages/integrity-search-history/integrity-search-history.component";
import { AuthGuardService as AuthGuard } from "./auth/auth-guard.service";
import { IdVerificationDashboardComponent } from "./id-verification/pages/dashboard/id-verification-dashboard.component";
import { VerificationsComponent } from "./verifications/verifications.component";
import { SuccessComponent as VerificationSuccessComponent } from "./verifications/success/success.component";
import { FailedComponent as VerificationFailedComponent } from "./verifications/failed/failed.component";
import { IdVerificationCheckComponent } from "./id-verification/pages/id-verification-check/id-verification-check.component";
import { ContributorInvitationComponent } from "./id-verification/pages/contributor-invitation/contributor-invitation.component";
import { ForbiddenAccessComponent } from "./forbidden-access/forbidden-access.component";
import { DefaultPageComponent } from "./ui/components/default-page/default-page.component";
import { TrustsComponent } from "./trusts/trusts.component";
import { CallbackComponent } from "./callback/callback.component";
import { ShareRequestComponent } from "./id-verification/pages/share-request/share-request.component";
import { ShareRequestUserComponent } from "./id-verification/pages/share-request/share-request-user/share-request-user.component";
import { AddressVerificationInvestorComponent } from "./id-verification/pages/address-verification-investor/address-verification-investor.component";
import { VerificationLandingComponent } from "./id-verification/pages/verification-landing/verification-landing.component";
import { ContractVerificationSubjectComponent } from "./id-verification/pages/contract-verification-subject/contract-verification-subject.component";
import { FormBuilderComponent } from "./ui/form-builder/form-builder.component";
import { BasicInformationVerificationSubjectComponent } from "./id-verification/pages/basic-information-verification-subject/basic-information-verification-subject.component";
import { QuestionnaireBuilderComponent } from "./ui/questionnaire-builder/questionnaire-builder.component";
import { KycTemplateDashboardComponent } from "./ui/kyc-templates/kyc-template-dashboard/kyc-template-dashboard.component";
import { WarningComponent } from "./verifications/warning/warning.component";
import { InvalidLinkPageComponent } from "./ui/components/invalid-link-page/invalid-link-page.component";
import { VerificationsGoComponent } from "./verifications/verifications-go.component";
import { SuccessComponentGo } from "./verifications/success/success-go.component";
import { ContractVerificationOnboardSubjectComponent } from "./id-verification/pages/contract-verification-onboard-subject/contract-verification-onboard-subject.component";
import { NewCddProcessControlScreenComponent } from "./id-verification/pages/share-request/new-cdd-process-control-screen/new-cdd-process-control-screen.component";
import { AddressShareDialogComponent } from "./id-verification/pages/share-request/new-cdd-process-control-screen/address-share-dialog/address-share-dialog.component";

export const ROUTES: Routes = [
  {
    path: "",
    component: DefaultPageComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "forbidden",
    component: ForbiddenAccessComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "verificationcheck/:verificationKey",
    component: IdVerificationCheckComponent,
    pathMatch: "full",
  },
  {
    path: "check/:verificationKey",
    component: IdVerificationCheckComponent,
    pathMatch: "full",
  },
  {
    path: "verificationcheck",
    component: IdVerificationCheckComponent,
    pathMatch: "full",
  },
  { path: "check", component: IdVerificationCheckComponent, pathMatch: "full" },
  {
    path: "id-verification/dashboard",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "id-verification/dashboard/:projectId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "id-verification/dashboard/:projectId/verifications/:verificationId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "id-verification/dashboard/:projectId/:verificationId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "funds/dashboard",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "investor/dashboard/:projectId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "investor/dashboard/:projectId/lightbox",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "investor/dashboard",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "funds/dashboard/:projectId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "funds/dashboard/:projectId/request/:requestId",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "funds/dashboard/:projectId/lightbox",
    component: IdVerificationDashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  {
    path: "integrity-check",
    component: IntegrityCheckComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "integrity-check/dashboard",
    component: IntegrityCheckResultComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "integrity-check/history",
    component: IntegritySearchHistoryComponent,
    canActivate: [AuthGuard],
  },
  { path: "verifications/failed", component: VerificationFailedComponent },
  {
    path: "verifications/:verificationKey/basicInfo",
    component: BasicInformationVerificationSubjectComponent,
  },
  {
    path: "verifications/:verificationKey/address",
    component: AddressVerificationInvestorComponent,
  },
  {
    path: "verifications/:verificationKey/contract",
    component: ContractVerificationSubjectComponent,
  },
  {
    path: "digital/verifications/:verificationKey/contract",
    component: ContractVerificationOnboardSubjectComponent,
  },
  // { path: 'verifications/data/failed', component: VerificationFailedComponent },
  { path: "verifications/error", component: VerificationFailedComponent },
  // { path: 'verifications/data/error', component: VerificationFailedComponent },
  { path: "verifications/success", component: VerificationSuccessComponent },
  // { path: 'verifications/data/success', component: VerificationSuccessComponent },
  {
    path: "verifications/:verificationKey/identity",
    component: VerificationsComponent,
  },
  {
    path: "digital/verifications/:verificationKey/address",
    component: AddressShareDialogComponent
  },
  {
    path: "digital/verifications/:verificationKey/identity",
    component: VerificationsGoComponent,
  },
  {
    path: "digital/verifications/identity/:verificationKey/success",
    component: SuccessComponentGo,
  },
  {
    path: "digital/verifications/identity/:verificationKey/error",
    component: VerificationFailedComponent,
  },
  {
    path: "verifications/:verificationKey/identity/warning",
    component: WarningComponent,
  },
  {
    path: "verifications/:verificationKey",
    component: VerificationLandingComponent,
  },
  {
    path: "verifications/:verificationKey/idin/resolve",
    component: VerificationLandingComponent,
  }, // idin resolve
  { path: "verifications", component: VerificationsComponent },
  {
    path: "invitations/:invitationKey",
    component: ContributorInvitationComponent,
    canActivate: [AuthGuard],
    data: { noRedirect: true },
  },
  { path: "trusts/requests/:cryptoKey", component: TrustsComponent },
  {
    path: "information/investor/share/:projectKey/:shareKey/pcs",
    component: IdVerificationDashboardComponent,
    canActivate: [AuthGuard],
    data: { noRedirect: true },
  },
  {
    path: "information/request/share/:shareKey",
    component: ShareRequestComponent,
  },
  {
    path: "information/request/share/:shareKey/user",
    component: ShareRequestUserComponent,
    canActivate: [AuthGuard],
    data: { noRedirect: true },
  },
  {
    path: "form-builder",
    component: FormBuilderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "questionnaires-builder",
    component: QuestionnaireBuilderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "templates",
    component: KycTemplateDashboardComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "invalid-link",
    component: InvalidLinkPageComponent,
    data: {
      errorMsg:
        "The link you are trying to open is either not a valid link or has already expired.",
      tooltipErrorMsg: `Invalid links are usually caused by missing characters in the link
      (e.g. when clicking a link from an email you received, your mail app may only pick up part of the link).
      If in doubt, please copy and paste the full link into the address bar of your browser.`,
    },
  },
  {
    path: "invalid-general",
    component: InvalidLinkPageComponent,
    data: {
      errorMsg: `Something went wrong.<br>Please
      <a href="mailto:support@singletruth.io">contact support</a> to resolve this issue.</div>
      <br>`,
    },
  },
  { path: "callback", component: CallbackComponent },
  { path: "callback", component: CallbackComponent },
  {
    path: "logout",
    component: DefaultPageComponent,
    pathMatch: "full",
    canActivate: [AuthGuard],
  },
  { path: "404", redirectTo: "/", pathMatch: "full", canActivate: [AuthGuard] },
  { path: "**", redirectTo: "/", pathMatch: "full", canActivate: [AuthGuard] },
];
