import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HelperServiceService } from 'src/app/helper-service.service';
import { IdVerificationService } from 'src/app/id-verification/id-verification.service';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-generate-verification-link-share-dialog',
  templateUrl: './generate-verification-link-share-dialog.component.html',
  styleUrls: ['./generate-verification-link-share-dialog.component.scss']
})
export class GenerateVerificationLinkShareDialogComponent implements OnInit {
  @Input() requestKey: string;
  @Input() profile;
  @Input() isJumioGo: boolean;
  @Input() verificationLink: any;
  entityName;
  digitalVerifications;
  loadingScreen = false;
  @Output() markCurrentStepAsCompleted = new EventEmitter<any>();

  constructor(
    public idVerificationService: IdVerificationService,
    public helperService: HelperServiceService,
    private userService: UserService,

  ) { }

  ngOnInit(): void {
    
    this.entityName = this.idVerificationService.generateEntityName(this.profile.typeOfInvestor, this.profile.investorFields);
    if(this.isJumioGo)
    {
      this.digitalVerifications = this.profile.identityGoStep;
    }
    else
    {
      this.digitalVerifications = this.profile.digitalVerifications
    }
    this.retreiveEvidenceData();
  }

  markCurrentStepAsCompletedTwo() {
    this.markCurrentStepAsCompleted.emit("");
  }


  initiateLink()
  {
    
    this.digitalVerifications.forEach( verifications =>
      {
        $(".dv-review-step-loading").addClass("d-flex");
        $(".dv-review-step").addClass("d-none");
        this.userService.postEndPoint(
          this.idVerificationService.buildEndPoint(
            "initiateDV",
            this.idVerificationService.getActiveProject(),
            this.requestKey,
            "",
            true,
            verifications.originalEvidenceKey? verifications.originalEvidenceKey : verifications.key,
          ),
          "",
          { responseType: "json" },
          { observe: "body" },
          "funds",
        ).subscribe(
          (response) => {
            if (response === undefined) {
              return;
            }
            this.verificationLink = response;
          },
          (error) => {
          },
        );

      });
  }

  retreiveEvidenceData() {
    this.loadingScreen = true;
    $(".right-pannel-wrapper").addClass("window-loading");
    
    this.userService
      .getEndPoint(
        "api/projects/" +
        this.idVerificationService.getActiveProject() +
        "/information/requests/" +
        this.requestKey +
        "/evidence/" +
        this.digitalVerifications[0].originalEvidenceKey,
        { responseType: "json" },
        { observe: "body" },
        "funds",
      )
      .subscribe((response) => {
        if(response["record"]?.reviewState == false)
        {
          this.markCurrentStepAsCompletedTwo();
        }
        this.loadingScreen = false;
        $(".right-pannel-wrapper").removeClass('window-loading');
      },(error) => {
        this.loadingScreen = false;
        $(".right-pannel-wrapper").removeClass("window-loading");
      });
  }
}
